import { Moment } from 'moment';
import {
	BaseColumns,
	CriteriaFunctionValue,
	CriteriaOperatorFunction,
	CriteriaSearchRequest,
	DefaultGridElement,
	GridDataColumnItem,
	GridItem,
	PaginationSetting,
	PreviewBadgeResponse,
	ResponseObject,
} from './CommonModel';
import { IdentificationFormatTypes } from './DeviceAdminModel';
import { UserDefinedFieldModel, UserDefinedFieldType } from './UserDefinedFields';

export type PivProfileResponse = {
	ProfilesEnabled: boolean;
	Profiles: PivProfile[];
};

export type PivEnrollment = {
	VelocityUrl: string;
	SelectedProfileId: number;
	PersonGroupId: number;
	PersonFolderId: number;
	PersonId: number;
} & PivProfileResponse;

export type PivProfile = {
	Id: number;
	Name: string;
	AgencyCode: string;
	SystemCode: string;
	ManualOverride: boolean;
	RDVFingerprint: boolean;
};

export type PivValidation = {
	FetchStatus: number;
	PivCardData: PivCardData;
	CertCheckResponse: CertCheckResponse;
	SelectedProfileId: number;
	PersonGroupId: number;
	PersonFolderId: number;
	PersonId: number;
};

export type PivCardData = {
	PIVName: string;
	PIVIssuerID: string;
	PIVAgencyCSN: string;
	PIVExpiration: string;
	PIVAffiliation1: string;
	PIVAffiliation2: string;
	FASCN: string;
	AC: string;
	SC: string;
	CN: string;
	CS: string;
	ICI: string;
	PI: string;
	OC: string;
	OI: string;
	POA: string;
	ED: string;
	GUID: string;
	DUNS: string;
	FacialImageBase64: string;
	CardChallenge: string;
	PivFascn: string;
	CardAuthCertificateFileName1: string;
	CardAuthCertificateType1: string;
	PIVAuthCertificateFileName2: string;
	PIVAuthCertificateType2: string;
	CHUID_AC: string;
	CHUID_OI: string;
	Line1: string;
	Line2: string;
};

export type CertCheckResponse = {
	OKToAcceptCard: boolean;
	CardChecks: string;
	CardAuthCert: string;
	PivAuthCert: string;
	FicamStatus: string;
	ValidationLogs: ValidationLog[];
};

export type ValidationLog = {
	Validator: string;
	Result: string;
};

export type PersonEnrollment = {
	PivCardData: PivCardData;
	SelectedProfileId: number;
	PersonGroupId: number;
	PersonFolderId: number;
	PersonId: number;
};

export type BadgeTemplate = {
	CanvasId: number;
	DisplayName: string;
};

export type CardFormat = {
	CardFormatId: number;
	CardFormatName: string;
};

export type TreeViewPersonGroupModel = {
	GroupId: number;
	FolderId: number;
	Name: string;
};

export type PersonGroups = {
	ChildGroups: TreeViewPersonGroupModel[];
} & TreeViewPersonGroupModel;

export type PersonTemplate = {
	PersonTemplateId: number;
	Name: string;
};

export type CredentialTemplate = {
	CredentialTemplateId: number;
	IDF: IdentificationFormatTypes;
	Description: string;
	IsDefault: boolean;
	HasLinkedCredentials: boolean;
};

export type EnrollmentTabInfo = {
	PersonGroups: PersonGroups[];
	PersonTemplates: PersonTemplate[];
	IsFICAMEnabled: boolean;
	CredentialTemplates: CredentialTemplate[];
	DefaultCredentialTemplateId: number;
	DisplayUserPhoto: boolean;
	ResourceStrings: EnrollmentResourceStrings;
} & EnrollmentBlankImages;

export type EnrollmentBlankImages = {
	BlankProfilePictureBase64?: string;
	BlankProfileSignatureBase64?: string;
};

export type PersonModel = ResponseObject &
	PersonFormModel & {
		PersonId: number;
		PhotoId: number;
		LastUpdated: Date;
		ShowProfilePicture: boolean;
	};

export type PersonDelete = {
	FirstName: string;
	LastName: string;
	Id: number;
};

export type UserSearchCriteria = {
	IsUDF: boolean;
	OperatorFunction: CriteriaOperatorFunction;
	FunctionVal: CriteriaFunctionValue;
	UdfNumber: number;
	UdfType: UserDefinedFieldType;
	FieldName: string;
	OrEqualTo: boolean;
	PrimaryField: string;
	SecondaryField: string;
	OrNext: string;
};

export type AdvanceSearchCriteria = CriteriaSearchRequest & {
	GroupId: number;
	FolderId: number;
};

export type UserSearchRequest = {
	UserSearchCriterias: UserSearchCriteria[];
};

export type PersonTableSetActionInfo = {
	personInformation: EnrollmentGridItem;
	paginationSettings: PaginationSetting;
	updateCounter: number;
};

export type EnrollmentGridItem = GridItem & {
	DisplayPicture: boolean;
};

export type EnrollmentResponse = ResponseObject & {
	PersonId: number;
	UserDefinedFieldsUpdated: boolean;
	UserDefinedFields: UserDefinedFieldModel[];
	FailedValidationReasons: FailedValidationReason[];
	FailedCredentialUpdates: FailedCredentialUpdateReason[];
	CountUdfViolations: boolean;
};

export type FailedValidationReason = {
	TabId: number;
	FieldNumber: number;
	Caption: string;
	Reason: FailedValidationReasonCodes;
	ErrorMessage: string;
	FormName: string;
};

export enum EnrollmentTabKey {
	Enrollment = 1,
	UnassignedAndGuestCredentials = 2,
}

export enum FailedValidationReasonCodes {
	None = 0,
	MustBeNumeric = 1,
	MustBeDate = 2,
	MustBeUnique = 3,
	ExceedsMaxLength = 4,
	RequiredFieldIsEmpty = 5,
	InvalidListItem = 6,
	FirstNameIsEmpty = 7,
	LastNameIsEmpty = 8,
	MustBeUUID = 9,
	NotPermissioned = 10,
	InvalidData = 11,
}

export enum CredentialType {
	FindCredential = -3,
	Guest = -2,
	Unassigned = -1,
	None = 0,
}

export enum CredentialSearchField {
	CredentialID = 0,
	PersonId = 1,
	CardStampNumber = 14,
	MatchCode = 15,
	PinCode = 16,
	Data = 17,
}

export enum PersonActionOptions {
	Edit,
	Delete,
}

export enum AddActionOptions {
	AddDefault,
	AddTemplate,
}

export enum PhotoActionOptions {
	Upload,
	Capture,
	Remove,
}

export enum CredentialActionOptions {
	None,
	Disable,
	Delete,
	Assign,
	Unassign,
	Revoke,
	Enable,
	Tag,
	Alert,
	ForgivePassback,
	OverrideCodeTamper,
	ResetLimitCount,
	ForceDownload,
	ViewPersonInformation,
	Preview,
	Print,
	Edit,
}

export enum CredentialAction {
	None,
	Disable,
	Delete,
	Assign,
	Unassign,
	Revoke,
	Enable,
	Tag,
	Alert,
	ForgivePassback,
	OverriddeCodeTamper,
	ResetLimitCount,
	ForceDownload,
}

export enum CredentialResponseCode {
	Success,
	MultipleFunctionSingleController,
	DuplicateMatch,
	DuplicatePin,
	InvalidMatchLength,
	InvalidPinLength,
	InvalidLimit,
	EnhancedPinSecurity,
	PrintControlRange,
	IssueControlRange,
	OldPinCode,
	OldMatchCode,
	MasterCode,
	OldPinCodeUsed,
	DualConflict,
	OldMatchCodeUsed,
	InvalidPin,
	InvalidMATCHCode,
	InvalidDefaultPin,
	DualConflictUsed,
	DuplicateDualCode,
	DuplicateCardCode,
	InputError,
	InvalidName,
	DuplicatedName,
}

export type ImageWithCredentialId = {
	Image: PreviewBadgeResponse;
	CredentialId: number;
};

export type PrintMultipleBadgeInfo = {
	Image: PreviewBadgeResponse;
	CredentialId: number;
	RestImagesWithCredentialId: ImageWithCredentialId[];
	Success: boolean;
};

export type CredentialActionResponse = ResponseObject & {
	FailedCredentialUpdates: FailedCredentialUpdateReason[];
};

export type FailedCredentialUpdateReason = {
	Reason: CredentialResponseCode;
	CredentialId: number;
	ErrorMessage: string;
};

export type CredentialDescription = {
	Id: number;
	Description: string;
};

export type CredentialOptionsHelper = {
	Id: number;
	IDF: IdentificationFormatTypes;
	Tag: boolean;
	Alert: boolean;
	Disabled: boolean;
	HostUserId: number;
};

export type CredentialActions = CredentialAction | CredentialActionOptions;

export enum CredentialColumnsKeys {
	IDF,
	CredentialId,
	FunctionDescription,
	HostDescription,
	Status,
	HostStatusComment,
	HostExpirationDate,
	LastAccess,
	LastAccessName,
	fTagUser,
	fAlertUser,
	HostCardStamp,
	HostUserId,
	LastName,
	FirstName,
}

export type SearchUniqueValues = {
	FolderId: number;
	GroupId: number;
	SearchText: string;
	PageNumber: number;
	PageSize: number;
};

export type PersonDetailModel = Partial<
	PersonModel & {
		SignatureId: number;
		DeleteProfilePicture: boolean;
		IsTemplate: boolean;
		TemplateId: number;
		Credentials: CredentialModel[];
		UserDefinedFields: Partial<UserDefinedFieldModel>[];
	}
>;

export class PersonFormModel {
	Title: string;
	Suffix: string;
	FirstName: string;
	LastName: string;
	MiddleName: string;
	ProfilePictureBase64: string;
	ProfileSignatureBase64: string;
	PersonGroupModified: boolean;
	PersonGroups: PersonGroupModel[];
	UploadedImages: UploadedImageModel[];
	AdditionalNotes: AdditionalNote[];
	CategoryId: string;
	NoteContent: string;
	CertificatesData: CertificatesData;

	constructor() {
		this.Title = '';
		this.Suffix = '';
		this.FirstName = '';
		this.LastName = '';
		this.MiddleName = '';
		this.ProfilePictureBase64 = '';
		this.ProfileSignatureBase64 = '';
		this.PersonGroupModified = false;
		this.PersonGroups = [];
		this.UploadedImages = [];
		this.AdditionalNotes = [];
		this.CategoryId = undefined;
		this.NoteContent = '';
		this.CertificatesData = null;
	}

	get FormKeysObject(): { [key in keyof PersonFormModel]: string } {
		return Object.assign(
			{},
			...Object.keys(this).map(keyString => {
				return { [keyString]: keyString };
			})
		);
	}
}

export type EnrollmentResourceStrings = {
	ViewTabError: string;
	DeleteAdditionalImage: string;
	Mr: string;
	Mrs: string;
	Ms: string;
	Dr: string;
	Jr: string;
	Sr: string;
};

export enum LimitRuleTypes {
	None = 0,
	Day,
	Use,
	Absentee,
}

export enum TwoPersonRuleTypes {
	Normal = 0,
	AB_RuleA,
	AB_RuleB,
	ExecutiveOverride,
}

export enum CredentialExpirationFunctions {
	Disable = 0,
	DeleteFromControllers,
	DeleteFromControllersWebLegacy,
	DisableAndUnassign,
}

export enum CredentialFunctionCategory {
	Access = 0,
	Relay = 1,
	Alarm = 2,
	Password = 3,
	Special = 4,
	FunctionGroup = 5,
	Undefined = 6,
}

export type EdgeDoorGroup = {
	CredentialId: number;
	DoorGroupId: number;
	FunctionIndex: number;
	FunctionName: string;
	DoorGroupName: string;
};

export type AssignCredentialDetail = {
	Eligible: AffectedCredential[];
	Ineligible: AffectedCredential[];
};

export type AffectedCredential = {
	PersonId: number;
	CredentialId: number;
	FirstName: string;
	LastName: string;
	Description: string;
};

export type CredentialModel = ResponseObject & {
	CredentialId: number;
	HostUserId: number;
	IDF: IdentificationFormatTypes;
	Description: string;
	ExpirationUsed: boolean;
	Expiration: Date;
	Tag: boolean;
	Alert: boolean;
	Disable: boolean;
	IsActivated: boolean;
	CurrentIssueStatus: string;
	HostExpirationUDF: number;
	HostBadgeTemplate: number;
	IsRevoked: boolean;
	HasUDFDataSelected: boolean;
};

export type CredentialReissue = {
	IssueNumber: number;
	Reason: string;
	Comment: string;
	Date: Date;
	Status: string;
};

export type PanelZone = {
	ControllerId: number;
	ZoneIndex: number;
	DeadmanTime: number;
};

export type CredentialFunction = {
	key: React.Key;
	CredentialFunctionId: number;
	FunctionIndex: number;
	HostZone: number;
	MasterDoorGroupId: number;
	IsMasterDoorGroup: boolean;
	FunctionCategory: CredentialFunctionCategory;
	FunctionName: string;
	Name: string;
	DisplayName: string;
	ControllerIds: number[];
	Zones: PanelZone[];
};

export type CredentialDetail = {
	AlwaysHidePin: boolean;
	MatchCode: string;
	PinCode: string;
	PinCodeLength: number;
	DuressDigit: number;
	ActivationDate: Date;
	BadgeTemplateId: number;
	ThreatLevel: number;
	Count: number;
	LinkedTemplateId: number;
	LimitRule: LimitRuleTypes;
	TwoPersonRule: TwoPersonRuleTypes;
	CardType: number;
	ExpirationFunction: CredentialExpirationFunctions;
	Globalize: boolean;
	ExecutiveOverride: boolean;
	SpecialNeeds: boolean;
	EnablePrintControl: boolean;
	PrintControlAttempts: number;
	PrintControlCount: number;
	PrintControlMax: number;
	PrintControlConfirmation: boolean;
	IssueControlCount: number;
	IssueControlMax: number;
	EnableIssueControl: boolean;
	IsLinked: boolean;
	IsTemplate: boolean;
	IsDefaultTemplate: boolean;
	CardData: string;
	CardStamp: string;
	ReservedFlag0_2: boolean;
	ReservedFlag3_2: boolean;
	ReservedFlag3_3: boolean;
	ReservedFlag3_4: boolean;
	ReservedFlag3_5: boolean;
	ReservedFlag3_6: boolean;
	ReservedFlag2_5: boolean;
	CardField: boolean;
	AutoDelete: boolean;
	ScrambleNormal: boolean;
	Owner: boolean;
	Dormant: boolean;
	PBZone: number;
	HostExpirationGAZ: number;
	HostIsActivated: boolean;
	HostExpirationTaskCompleted: boolean;
	HostActivationInterval: number;
	HostActivationOffset: string;
	HostExpirationOffset: string;
	HostExpirationInterval: number;
	LinkNew: boolean;
	EdgeDoorGroup: EdgeDoorGroup[];
	CredentialReissueInfo: CredentialReissue[];
	CredentialFunctions: CredentialFunction[];
	ConcatenatedUDFFields: number[];
	UpdateConcatenatedFields: boolean;
	CredentialTemplateId: number;
	UserCertificateType: string;
	UserCertificateHash: string;
	EdgeFunction: number;
	ReaderControlGroupId: number;
	TwoHundredBit: boolean;
	LinkToExisting: boolean;
} & CredentialModel &
	OverrideAnswer;

export type OverrideAnswer = {
	PinOverrideAnswer: boolean;
	CodeOverrideAnswer: boolean;
	DualOverrideAnswer: boolean;
};

export type UploadedImageModel = {
	FileName: string;
	ImagePlaceHolderId: number;
	Description: string;
	UploadType: ImageUploadType;
	DeleteImage: boolean;
	ImageBase64: string;
};

export enum ImageUploadType {
	Portrait,
	Signature,
	AdditionalImage,
}

export type PersonGroupModel = {
	PersonGroupId: number;
	GroupName: string;
	PersonFolderId: number;
	FolderName: string;
	ParentFolderId: number;
	Selected: boolean;
};

export type CertificatesData = {
	FaceImage: string;
	PivAuthCertFileName2: string;
	CardAuthCertFileName1: string;
	ChuidCertFileName4: string;
	PivAuthCertType2: string;
	CardAuthCertType1: string;
	ChuidCertType4: string;
	UUID: string;
	CertSHA256Hash1: string;
	CertSHA256Hash2: string;
	CertSHA256Hash3: string;
	CertSHA256Hash4: string;
	FPAuthCertType3: string;
	ProfileId: string;
	ValLogId: string;
};

export type AdditionalImageModel = {
	ImageId: number;
	Caption: string;
	Description: string;
	ImagePlaceHolderId: number;
	ImageThumbnailBase64: string;
	ImagePortraitBase64: string;
};

export type AdditionalImageList = ResponseObject & {
	AdditionalImages: AdditionalImageModel[];
	ShowProfilePicture: boolean;
	TotalItems: number;
};

export type AdditionalNotesCategory = {
	CategoryId: number;
	CategoryName: string;
};

export type AdditionalNote = {
	NoteId: number;
	UserId: number;
	CategoryId: number;
	NoteContent: string;
};

export type initialFormValueObject = {
	[key: string]: string | number | boolean | Moment | PersonGroupModel[] | UploadedImageModel[] | AdditionalNote[] | CertificatesData;
};

export type CredentialResponse = {
	Columns: GridDataColumnItem[];
	Data: CredentialGridItem[];
	TotalItems: number;
	PaginationSetting: PaginationSetting;
};

export type CredentialGridItem = {
	HostUserId: number;
	LastName: string;
	FirstName: string;
	IDFNumber: number;
	IDF: string;
	CredentialId: number;
	FunctionDescription: string;
	HostDescription: string;
	Status: string;
	HostStatusComment: string;
	LastAccess?: Date;
	LastAccessName: string;
	fTagUser: boolean;
	fAlertUser: boolean;
	HostIsActivated: boolean;
	fDeactivated: boolean;
	HostExpirationDate: string;
	HostCardStamp: string;
} & DefaultGridElement &
	BaseColumns;

export type FunctionModel = {
	FunctionIndex: number;
	DefaultFunctionName: string;
	FunctionName: string;
};

export type FunctionCategory = {
	FunctionCategoryIndex: number;
	DefaultCategoryName: string;
	CategoryName: string;
	Functions?: FunctionModel[];
};

export type XboxController = {
	ControllerId: number;
	ControllerName: string;
	ServerId: number;
	PortId: number;
	XboxId: number;
};

export type FunctionGroup = {
	FunctionGroupId: number;
	Name: string;
	Defined: boolean;
	ControllerIds: number[];
};

export type ThreatLevel = {
	ThreatId: number;
	Name: string;
};

export type RelativeShortcut = {
	Index: number;
	Interval: number;
	OffSetOrTime: string;
	Display: string;
	IntervalName: string;
};

export type ConcatenatedCardData = {
	FieldNumber: number;
	OrderIndex: number;
	Caption: string;
	Selected: boolean;
	Value: string;
};

export type CredentialMessages = {
	Print: string;
	Preview: string;
	SelectFirst: string;
};
