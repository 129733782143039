import { BroadCastSummary } from './DeviceAdminModel';
import { LiveEvents } from './EventModel';
import { PhotoCallUp } from './PhotoCallUpModel';
import { DigiTracTransaction, PerformanceData } from './StatusModel';
import { StatusViewerEvent } from './StatusViewerModel';
import { WhosInsideActionDetail } from './WhosInsideModel';

export enum SubscriptionType {
	None = 0,
	StatusSummary = 1,
	Events = 2,
	Alarms = 4,
	PhotoCallUp = 8,
	StatusViewer = 16,
	WhosInside = 32,
	QueuedDownloads = 64,
	AccessSummary = 128,
	DeviceControl = 256,
	PerformanceStatus = 512,
}

export type Subscription = {
	SessionId: string;
	SubscriptionType: SubscriptionType;
};

export type WebSocketNotification = {
	ForceLogOff: boolean;
	PIVEnrollmentSaved: boolean;
	PerformanceStatus: PerformanceData;
	LiveEvents: LiveEvents;
	BroadCastDeviceSummary: BroadCastSummary;
	WhosInsideActionDetails: WhosInsideActionDetail[];
	StatusViewerEvents: StatusViewerEvent[];
	PhotoCallCredentials: PhotoCallUp[];
	Transactions: DigiTracTransaction[];
};
