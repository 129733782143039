import { ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Divider, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { getFullVelocityUrl } from '../../../Helper';
import { enrollmentApi } from '../../../api/';
import { PersonDetailModel, PivProfileResponse, PivValidation } from '../../../model/EnrollmentModel';
import { Logger } from '../../../model/LoggingModel';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import { selectPIVEnrollment } from '../../../store/common/selectors';
import {
	setDisplayAddViaSmartCard,
	setFetchingScanData,
	setHavePersonChanged,
	setIsEnrollmentTabLoading,
	setPersonInformationId,
} from '../../../store/enrollment/actions';
import { selectFetchingScanData, selectPersonId, selectPersonPIVFullName } from '../../../store/enrollment/selectors';
import { PIVCardInfo, PIVHeader } from '../../enrollment';
import { enrollmentGoBackTextId } from '../helper';
import styles from './pivContainer.module.scss';

const velocityUrl = getFullVelocityUrl();
const downloadPivReaderUrl = `${velocityUrl}Enrollment/DownloadWebPIVReader`;
const enrollmentManagerUrl = `${velocityUrl}Enrollment/EnrollmentManager`;

type Props = {
	goBackText: string;
	setUpdatePivPersonData?: (newValue: PersonDetailModel) => void;
};

const PIVContainer: React.FC<Props> = ({ goBackText, setUpdatePivPersonData }) => {
	const dispatch = useStoreDispatch();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [validationResult, setValidationResult] = useState<PivValidation>(null);
	const [validationProfileResponse, setValidationProfileResponse] = useState<PivProfileResponse>(null);

	const personId: number = useStoreSelector<number>(selectPersonId);
	const fullName: string = useStoreSelector<string>(selectPersonPIVFullName);
	const fetchingScanData: boolean = useStoreSelector<boolean>(selectFetchingScanData);
	const pivEnrollmentSaved: boolean = useStoreSelector<boolean>(selectPIVEnrollment);

	useEffect(() => {
		enrollmentApi
			.retrieveValidationProfiles()
			.then(response => {
				batch(() => {
					setIsLoading(false);
					setValidationProfileResponse(response);
				});
			})
			.catch(e => Logger.writeErrorLog(e));
	}, []);

	useEffect(() => {
		if (pivEnrollmentSaved) {
			enrollmentApi
				.retrievePivEnrollment()
				.then(data => {
					//Status = 1 means there was an update and we need to redirect to enrollment screen
					if (data.FetchStatus === 1) {
						window.location.href = enrollmentManagerUrl;
					} else {
						batch(() => {
							//Status 2 means we had a record on the DB and we will process that record to re-render screen
							const hasChange = data.FetchStatus === 2;
							if (hasChange) {
								handleFetchScanData();
							}

							setIsLoading(false);
							setValidationResult(data);
						});
					}
				})
				.catch(e => Logger.writeErrorLog(e));
		}
	}, [pivEnrollmentSaved]);

	const handleFetchScanData = () => {
		dispatch(setFetchingScanData(false));
	};

	const handleReturnToEnrollmentInAddPIV = () => {
		dispatch(setDisplayAddViaSmartCard(false));
	};

	const handleReturnToEnrollmentInUpdatePIV = () => {
		batch(() => {
			dispatch(setPersonInformationId(0));
			dispatch(setIsEnrollmentTabLoading(false));
			dispatch(setDisplayAddViaSmartCard(false));
			dispatch(setHavePersonChanged(false));
		});
	};

	const handleOnClickBack = e => {
		if (personId !== 0) {
			handleReturnToEnrollmentInUpdatePIV();
		} else {
			handleReturnToEnrollmentInAddPIV();
		}
	};

	const handleOnGoBackToPerson = e => {
		handleReturnToEnrollmentInAddPIV();
	};

	const breadCrumbTitle =
		personId === 0 ? (
			<Breadcrumb.Item>{_('AddPersonViaSmartCard')}</Breadcrumb.Item>
		) : (
			<>
				<Breadcrumb.Item>
					<a onClick={handleOnGoBackToPerson}>
						<label>{fullName}</label>
					</a>
				</Breadcrumb.Item>
				<Breadcrumb.Item>{_('UpdatePersonViaSmartCard')}</Breadcrumb.Item>
			</>
		);

	const showCreatePerson = !fetchingScanData && validationResult && validationResult.CertCheckResponse.OKToAcceptCard;

	return (
		<div className={styles.container}>
			<div>
				<Breadcrumb separator='/'>
					<Breadcrumb.Item>
						<a onClick={handleOnClickBack}>
							<ArrowLeftOutlined /> <label id={enrollmentGoBackTextId}>{goBackText}</label>
						</a>
					</Breadcrumb.Item>
					{breadCrumbTitle}
				</Breadcrumb>
			</div>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className={styles.spinContainer}>
				<div className={styles.modalMock}>
					<p className={styles.modalTitle}>{_('VerifySmartCardInformation')}</p>
					<Divider className={styles.divider} />
					<PIVHeader
						validationProfileResponse={validationProfileResponse}
						validationResult={validationResult}
						showCreatePerson={showCreatePerson}
						downloadPivReaderUrl={downloadPivReaderUrl}
						setUpdatePivPersonData={setUpdatePivPersonData}
					/>
					<Divider className={styles.divider} />
					{!fetchingScanData && !validationResult && (
						<div className={styles.optionsContainer}>
							<span>
								{_('EnrollmentPIVReaderDownload')} <a href={downloadPivReaderUrl}>{_('DownloadNow')}</a>
							</span>
						</div>
					)}
					{fetchingScanData && <span className={styles.instructions}>{_('EnrollmentInstructions')}</span>}
					{!fetchingScanData && validationResult && <PIVCardInfo validationResult={validationResult} />}
				</div>
			</Spin>
		</div>
	);
};

export { PIVContainer };
