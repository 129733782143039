import { batch } from 'react-redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { deviceAdminApi } from '../../api';
import { applyCurrentEventFilters, getFullVelocityUrl } from '../../Helper';
import { AlarmModel } from '../../model/AlarmModel';
import { ConfigurationState } from '../../model/ConfigurationModel';
import { BroadCastSummary, CurrentDeviceControlObj, DeviceObjectType, ModalTimeZones, ObjectTypes, TimeZoneType } from '../../model/DeviceAdminModel';
import { LiveEvents, StatusSummary, VelocityEventModel } from '../../model/EventModel';
import { PhotoCallUp } from '../../model/PhotoCallUpModel';
import { DigiTracTransaction, PerformanceData } from '../../model/StatusModel';
import { StatusViewerEvent } from '../../model/StatusViewerModel';
import { WebSocketNotification } from '../../model/WebSocketModel';
import { WhosInsideActionDetail } from '../../model/WhosInsideModel';
import { RootReducer } from '../rootReducer';
import { CommonActionType } from './actionsTypes';

type CommonClearSocketResponse = {
	type: CommonActionType.CLEAR_SOCKET_RESPONSE;
};

type CommonSetSocketPIVEnrollmentResponse = {
	type: CommonActionType.SET_SOCKET_PIV_ENROLLMENT_RESPONSE;
	payload: boolean;
};

type CommonSetSocketPerformanceDataResponse = {
	type: CommonActionType.SET_SOCKET_PERFORMANCE_STATUS_RESPONSE;
	payload: PerformanceData;
};

type CommonSetSocketStatusSummaryResponse = {
	type: CommonActionType.SET_SOCKET_STATUS_SUMMARY_RESPONSE;
	payload: StatusSummary;
};

type CommonSetSocketEventListResponse = {
	type: CommonActionType.SET_SOCKET_EVENT_LIST_RESPONSE;
	payload: VelocityEventModel[];
};

type CommonSetSocketAlarmListResponse = {
	type: CommonActionType.SET_SOCKET_ALARM_LIST_RESPONSE;
	payload: AlarmModel[];
};

type CommonSetSocketAckAlarmListResponse = {
	type: CommonActionType.SET_SOCKET_ACK_ALARM_LIST_RESPONSE;
	payload: AlarmModel[];
};

type CommonSetSocketClearAlarmListResponse = {
	type: CommonActionType.SET_SOCKET_CLEAR_ALARM_LIST_RESPONSE;
	payload: AlarmModel[];
};

type CommonSetSocketWhosInsideActionDetailsResponse = {
	type: CommonActionType.SET_SOCKET_WHOS_INSIDE_ACTION_DETAILS_RESPONSE;
	payload: WhosInsideActionDetail[];
};

type CommonSetSocketStatusViewerEventsResponse = {
	type: CommonActionType.SET_SOCKET_STATUS_VIEWER_EVENTS_RESPONSE;
	payload: StatusViewerEvent[];
};

type CommonSetSocketPhotoCallUpCredentialsResponse = {
	type: CommonActionType.SET_SOCKET_PHOTO_CALLUP_CREDENTIALS_RESPONSE;
	payload: PhotoCallUp[];
};

type CommonSetSocketTransactionsResponse = {
	type: CommonActionType.SET_SOCKET_TRANSACTIONS_RESPONSE;
	payload: DigiTracTransaction[];
};

type CommonSetSocketBroadCastSummaryResponse = {
	type: CommonActionType.SET_SOCKET_BROADCAST_SUMMARY_RESPONSE;
	payload: BroadCastSummary;
};

type DeviceControlSetLoading = {
	type: CommonActionType.SET_LOADING;
	payload: boolean;
};

type DeviceControlSetCurrent = {
	type: CommonActionType.SET_CURRENT_DEVICE;
	payload: CurrentDeviceControlObj;
};

type DeviceControlSetCurrentTimeZone = {
	type: CommonActionType.SET_CURRENT_TIME_ZONE;
	payload: ModalTimeZones;
};

type DeviceControlSetSelectedTimeZone = {
	type: CommonActionType.SET_SELECTED_TIME_ZONE;
	payload: TimeZoneType;
};

type CommonSelectedTree = {
	type: CommonActionType.SET_ACTIVE_TREE_SELECTED_KEY;
	payload: string;
};

type CommonSetTotalItemsPagination = {
	type: CommonActionType.SET_TOTAL_ITEMS_PAGINATION;
	payload: number;
};

type CommonSetItemSearchedPagination = {
	type: CommonActionType.SET_ITEM_SEARCHED_PAGINATION;
	payload: string;
};

type CommonSetAllItemsPagination = {
	type: CommonActionType.SET_ALL_ITEMS_PAGINATION;
	payload: string[];
};

type CommonSetConfiguration = {
	type: CommonActionType.SET_CONFIGURATION;
	payload: ConfigurationState;
};

//Union Action Types
export type CommonAction =
	| CommonClearSocketResponse
	| CommonSetSocketPIVEnrollmentResponse
	| CommonSetSocketPerformanceDataResponse
	| CommonSetSocketStatusSummaryResponse
	| CommonSetSocketEventListResponse
	| CommonSetSocketAlarmListResponse
	| CommonSetSocketAckAlarmListResponse
	| CommonSetSocketClearAlarmListResponse
	| CommonSetSocketWhosInsideActionDetailsResponse
	| CommonSetSocketStatusViewerEventsResponse
	| CommonSetSocketPhotoCallUpCredentialsResponse
	| CommonSetSocketTransactionsResponse
	| CommonSetSocketBroadCastSummaryResponse
	| DeviceControlSetLoading
	| DeviceControlSetCurrent
	| DeviceControlSetCurrentTimeZone
	| DeviceControlSetSelectedTimeZone
	| CommonSelectedTree
	| CommonSetTotalItemsPagination
	| CommonSetItemSearchedPagination
	| CommonSetAllItemsPagination
	| CommonSetConfiguration;

//Action Creators
const clearSocketResponseAction = (): CommonClearSocketResponse => {
	return {
		type: CommonActionType.CLEAR_SOCKET_RESPONSE,
	};
};

const setSocketPIVEnrollmentResponseAction = (payload: boolean): CommonSetSocketPIVEnrollmentResponse => {
	return {
		type: CommonActionType.SET_SOCKET_PIV_ENROLLMENT_RESPONSE,
		payload,
	};
};

const setSocketPerformanceStatusResponseAction = (payload: PerformanceData): CommonSetSocketPerformanceDataResponse => {
	return {
		type: CommonActionType.SET_SOCKET_PERFORMANCE_STATUS_RESPONSE,
		payload,
	};
};

const setSocketStatusSummaryResponseAction = (payload: StatusSummary): CommonSetSocketStatusSummaryResponse => {
	return {
		type: CommonActionType.SET_SOCKET_STATUS_SUMMARY_RESPONSE,
		payload,
	};
};

const setSocketEventListResponseAction = (payload: VelocityEventModel[]): CommonSetSocketEventListResponse => {
	return {
		type: CommonActionType.SET_SOCKET_EVENT_LIST_RESPONSE,
		payload,
	};
};

const setSocketAlarmListResponseAction = (payload: AlarmModel[]): CommonSetSocketAlarmListResponse => {
	return {
		type: CommonActionType.SET_SOCKET_ALARM_LIST_RESPONSE,
		payload,
	};
};

const setSocketAckAlarmListResponseAction = (payload: AlarmModel[]): CommonSetSocketAckAlarmListResponse => {
	return {
		type: CommonActionType.SET_SOCKET_ACK_ALARM_LIST_RESPONSE,
		payload,
	};
};

const setSocketClearAlarmListResponseAction = (payload: AlarmModel[]): CommonSetSocketClearAlarmListResponse => {
	return {
		type: CommonActionType.SET_SOCKET_CLEAR_ALARM_LIST_RESPONSE,
		payload,
	};
};

const setSocketWhosInsideActionDetailsResponseAction = (payload: WhosInsideActionDetail[]): CommonSetSocketWhosInsideActionDetailsResponse => {
	return {
		type: CommonActionType.SET_SOCKET_WHOS_INSIDE_ACTION_DETAILS_RESPONSE,
		payload,
	};
};

const setSocketStatusViewerEventsResponseAction = (payload: StatusViewerEvent[]): CommonSetSocketStatusViewerEventsResponse => {
	return {
		type: CommonActionType.SET_SOCKET_STATUS_VIEWER_EVENTS_RESPONSE,
		payload,
	};
};

const setSocketPhotoCallUpCredentialsResponseAction = (payload: PhotoCallUp[]): CommonSetSocketPhotoCallUpCredentialsResponse => {
	return {
		type: CommonActionType.SET_SOCKET_PHOTO_CALLUP_CREDENTIALS_RESPONSE,
		payload,
	};
};

const setSocketTransactionsResponseAction = (payload: DigiTracTransaction[]): CommonSetSocketTransactionsResponse => {
	return {
		type: CommonActionType.SET_SOCKET_TRANSACTIONS_RESPONSE,
		payload,
	};
};

const setSocketBroadCastSummaryResponseAction = (payload: BroadCastSummary): CommonSetSocketBroadCastSummaryResponse => {
	return {
		type: CommonActionType.SET_SOCKET_BROADCAST_SUMMARY_RESPONSE,
		payload,
	};
};

const setLoadingAction = (payload: boolean): DeviceControlSetLoading => {
	return {
		type: CommonActionType.SET_LOADING,
		payload,
	};
};

export const setCurrentDevice = (payload: CurrentDeviceControlObj): DeviceControlSetCurrent => {
	return {
		type: CommonActionType.SET_CURRENT_DEVICE,
		payload,
	};
};

const setCurrentModalTimeZoneAction = (payload: ModalTimeZones): DeviceControlSetCurrentTimeZone => {
	return {
		type: CommonActionType.SET_CURRENT_TIME_ZONE,
		payload,
	};
};

const setSelectedTimeZoneAction = (payload: TimeZoneType): DeviceControlSetSelectedTimeZone => {
	return {
		type: CommonActionType.SET_SELECTED_TIME_ZONE,
		payload,
	};
};

export const setActiveTreeKey = (payload: string): CommonSelectedTree => {
	return {
		type: CommonActionType.SET_ACTIVE_TREE_SELECTED_KEY,
		payload,
	};
};

const setTotalItemsPaginationAction = (payload: number): CommonSetTotalItemsPagination => {
	return {
		type: CommonActionType.SET_TOTAL_ITEMS_PAGINATION,
		payload,
	};
};

const setItemSearchedPaginationAction = (payload: string): CommonSetItemSearchedPagination => {
	return {
		type: CommonActionType.SET_ITEM_SEARCHED_PAGINATION,
		payload,
	};
};

export const setAllItemsPaginationAction = (payload: string[]): CommonSetAllItemsPagination => {
	return {
		type: CommonActionType.SET_ALL_ITEMS_PAGINATION,
		payload,
	};
};

export const setConfiguration = (payload: ConfigurationState): CommonSetConfiguration => {
	return {
		type: CommonActionType.SET_CONFIGURATION,
		payload,
	};
};

//Export Action
export const setSocketResponse = (response: WebSocketNotification): ThunkAction<Promise<void>, {}, {}, CommonAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, CommonAction>, getState: () => RootReducer): Promise<void> => {
		batch(() => {
			if (response.ForceLogOff) {
				const fullVelocityUrl: string = getFullVelocityUrl();
				const url = `${fullVelocityUrl}Account/LogOff`;
				window.location.href = url;
			}

			if (response.PIVEnrollmentSaved) {
				dispatch(setSocketPIVEnrollmentResponseAction(response.PIVEnrollmentSaved));
			}

			if (response.PerformanceStatus) {
				dispatch(setSocketPerformanceStatusResponseAction(response.PerformanceStatus));
			}

			if (response.LiveEvents.StatusSummaryHasChanges) {
				dispatch(setSocketStatusSummaryResponseAction(response.LiveEvents.StatusSummary));
			}

			if (response.LiveEvents.EventSummary.EventList.length) {
				const rootReducer: RootReducer = getState();
				const { eventFilters, selectedCustomFilterGroups } = rootReducer.eventReducer;
				const updatedDataState: VelocityEventModel[] = applyCurrentEventFilters(
					[...response.LiveEvents.EventSummary.EventList],
					eventFilters,
					selectedCustomFilterGroups
				);
				if (updatedDataState.length) {
					dispatch(setSocketEventListResponseAction(updatedDataState));
				}
			}

			if (response.LiveEvents.EventSummary.AlarmList.length) {
				dispatch(setSocketAlarmListResponseAction(response.LiveEvents.EventSummary.AlarmList));
			}

			if (response.LiveEvents.EventSummary.AckAlarmList.length) {
				dispatch(setSocketAckAlarmListResponseAction(response.LiveEvents.EventSummary.AckAlarmList));
			}

			if (response.LiveEvents.EventSummary.ClearedAlarmList.length) {
				dispatch(setSocketClearAlarmListResponseAction(response.LiveEvents.EventSummary.ClearedAlarmList));
			}

			if (response.BroadCastDeviceSummary.SearchCompleted || response.BroadCastDeviceSummary.Devices.length) {
				dispatch(setSocketBroadCastSummaryResponseAction(response.BroadCastDeviceSummary));
			}

			if (response.WhosInsideActionDetails.length) {
				dispatch(setSocketWhosInsideActionDetailsResponseAction(response.WhosInsideActionDetails));
			}

			if (response.StatusViewerEvents.length) {
				dispatch(setSocketStatusViewerEventsResponseAction(response.StatusViewerEvents));
			}

			if (response.PhotoCallCredentials.length) {
				dispatch(setSocketPhotoCallUpCredentialsResponseAction(response.PhotoCallCredentials));
			}

			if (response.Transactions.length) {
				dispatch(setSocketTransactionsResponseAction(response.Transactions));
			}
		});

		dispatch(clearSocketResponseAction());
	};
};

export const setSocketLiveEventsResponse = (response: LiveEvents): ThunkAction<Promise<void>, {}, {}, CommonAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, CommonAction>): Promise<void> => {
		batch(() => {
			if (response.StatusSummaryHasChanges) {
				dispatch(setSocketStatusSummaryResponseAction(response.StatusSummary));
			}

			if (response.EventSummary.EventList.length) {
				dispatch(setSocketEventListResponseAction(response.EventSummary.EventList));
			}

			if (response.EventSummary.AlarmList.length) {
				dispatch(setSocketAlarmListResponseAction(response.EventSummary.AlarmList));
			}

			if (response.EventSummary.AckAlarmList.length) {
				dispatch(setSocketAckAlarmListResponseAction(response.EventSummary.AckAlarmList));
			}

			if (response.EventSummary.ClearedAlarmList.length) {
				dispatch(setSocketClearAlarmListResponseAction(response.EventSummary.ClearedAlarmList));
			}
		});
	};
};

export const setLoading = (isLoading: boolean): ThunkAction<Promise<void>, {}, {}, CommonAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, CommonAction>): Promise<void> => {
		dispatch(setLoadingAction(isLoading));
	};
};

export const setCurrentModalTimeZone = (deviceType: ModalTimeZones): ThunkAction<Promise<void>, {}, {}, CommonAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, CommonAction>): Promise<void> => {
		dispatch(setCurrentModalTimeZoneAction(deviceType));
	};
};

export const setSelectedTimeZone = (type: TimeZoneType): ThunkAction<Promise<void>, {}, {}, CommonAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, CommonAction>): Promise<void> => {
		dispatch(setSelectedTimeZoneAction(type));
	};
};

export const setTotalItemsPagination = (totalTimezones: number): ThunkAction<Promise<void>, {}, {}, CommonAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, CommonAction>): Promise<void> => {
		dispatch(setTotalItemsPaginationAction(totalTimezones));
	};
};

export const setItemSearchedPagination = (timeZoneSearched: string): ThunkAction<Promise<void>, {}, {}, CommonAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, CommonAction>): Promise<void> => {
		dispatch(setItemSearchedPaginationAction(timeZoneSearched));
	};
};

const getDeviceObjectData = async (deviceObjectType: DeviceObjectType, objectType: ObjectTypes): Promise<string[]> => {
	switch (deviceObjectType) {
		case DeviceObjectType.TimeZones:
			if (objectType === ObjectTypes.timeZones) {
				const res = await deviceAdminApi.retrieveStandardTimeZones();
				return res.Entity.map<string>(x => x.Id.toString());
			} else if (objectType === ObjectTypes.masterTimeZones) {
				const res = await deviceAdminApi.retrieveMasterTimeZones();
				return res.Entity.map<string>(x => x.Id.toString());
			} else if (objectType === ObjectTypes.grandMasterTimeZones) {
				const res = await deviceAdminApi.retrieveGrandMasterTimeZones();
				return res.Entity.map<string>(x => x.Id.toString());
			}
			break;
	}
};

export const setAllItemsPagination = (deviceObjectType: DeviceObjectType, objectType?: ObjectTypes): ThunkAction<Promise<void>, {}, {}, CommonAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, CommonAction>): Promise<void> => {
		const data: string[] = await getDeviceObjectData(deviceObjectType, objectType);
		dispatch(setAllItemsPaginationAction(data));
	};
};
